html,
body {
  margin: 0;
  padding: 0;
}
html,
body,
#root {
  height: 100%;
}

.iconfont {
  font-size: 1em;
  padding: 0 3px;
}
