.editor {
  height: 100%;
  display: flex;
  position: relative;
}
.aside {
  display: flex;
  flex-direction: column;
  width: 200px;
  overflow-y: scroll;
}

.folder {
  flex: 1;
}
.textArea {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
