.list {
  height: 100%;
  border-right: 1px solid rgb(236, 232, 232);
  box-sizing: border-box;
  background: rgba(236, 232, 232, 0.7);
}

.header {
  padding: 15px 10px;
  box-sizing: border-box;
  border-top: 1px solid rgb(236, 232, 232);
  border-bottom: 1px solid rgb(236, 232, 232);
}
.header a {
  color: rgb(177, 174, 174);
  text-decoration: none;
}
.folderList {
  margin: 0;
  padding: 0;
}
.folderList li {
  box-sizing: border-box;
  padding: 7px 10px;
  list-style: none;
  cursor: pointer;
  border-bottom: 1px solid rgb(236, 232, 232);
}

.folderList li:hover {
  background: #fff;
}
