@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?uavdyb');
  src:  url('fonts/icomoon.eot?uavdyb#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?uavdyb') format('truetype'),
    url('fonts/icomoon.woff?uavdyb') format('woff'),
    url('fonts/icomoon.svg?uavdyb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-header:before {
  content: "\e902";
}
.icon-wechat:before {
  content: "\e900";
}
.icon-weixin:before {
  content: "\e900";
}
.icon-columns:before {
  content: "\e901";
}
.icon-pencil:before {
  content: "\e905";
}
.icon-image:before {
  content: "\e90d";
}
.icon-stack:before {
  content: "\e92e";
}
.icon-folder-open:before {
  content: "\e930";
}
.icon-folder-plus:before {
  content: "\e931";
}
.icon-folder-download:before {
  content: "\e933";
}
.icon-price-tag:before {
  content: "\e935";
}
.icon-qrcode:before {
  content: "\e938";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-location:before {
  content: "\e947";
}
.icon-undo:before {
  content: "\e965";
}
.icon-redo:before {
  content: "\e966";
}
.icon-quotes-left:before {
  content: "\e977";
}
.icon-search:before {
  content: "\e986";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-list-numbered:before {
  content: "\e9b9";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-question:before {
  content: "\ea09";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-bold:before {
  content: "\ea62";
}
.icon-italic:before {
  content: "\ea64";
}
.icon-strikethrough:before {
  content: "\ea65";
}
.icon-table2:before {
  content: "\ea71";
}
.icon-insert-template:before {
  content: "\ea72";
}
.icon-embed2:before {
  content: "\ea80";
}
.icon-github:before {
  content: "\eab0";
}
.icon-file-pdf:before {
  content: "\eadf";
}
