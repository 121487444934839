.dropdown {
  width: 110px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  position: absolute;
  /* 防止第三方 */
  z-index: 9999;
  position: fixed;
  box-shadow: 1px 1px 5px #d1d1d1;
}
.link {
  display: block;
  text-align: left;
  margin: 0 !important;
  padding: 0 9px;
  line-height: 26px;
  text-decoration: none;
  background: #fff;
  color: #333;
}
.link:hover {
  background: rgb(231, 225, 225);
}
